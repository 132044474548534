.container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Input {
  margin-top: 8px;
  font-family: 'WH Hoxton Regular';
  color: var(--secondary-text);
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  padding: 11px 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  flex-grow: 1;
  outline: none;
}

.Input:disabled {
  background: var(--secondary-background);
}

.input-centre-text {
  text-align: center;
}

.input-flex-grow-right {
  flex-grow: 4.8;
}

.input-flex-grow-left {
  flex-grow: 1.5;
}

.input-flex-grow-left-secondary {
  flex-grow: 2.15;
}

.input-flex-grow-left-tertiary {
  flex-grow: 1.6;
}

.input-error {
  border: 1.6px solid var(--primary-red);
}

.active {
  border: 1.6px solid var(--secondary-border);
  box-shadow: 0px 0px 16px 1px var(--primary-box-shadow);
}

.input-prompt {
  border: 1.6px solid var(--autofill-prompt-text);
}

.input-success {
  border: 1.6px solid var(--primary-green);
}

.pin-text {
  margin-top: 8px;
  font-family: 'WH Hoxton Regular';
  color: var(--primary-text);
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  padding: 11px 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  flex-grow: 1.2;
  outline: none;
}

#input-prompt-label{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
}