.account-details-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.account-details-arrow-down {
  position: absolute;
  top: 32px;
  right: 18px;
}

.account-details-deposit-limit {
  margin-top: 8px;
  font-family: 'WH Hoxton Regular';
  color: var(--secondary-text);
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  padding: 11px 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  flex-grow: 1;
}

.account-details-deposit-limit-options-container {
  box-shadow: 0px 0px 16px 1px var(--primary-box-shadow);
  border-radius: 4px;
  margin-top: 8px;
  position: absolute;
  background: var(--secondary-background);
  width: 800px;
  height: 360px;
  overflow-y: scroll;
  top: 62px;
}

.account-details-deposit-limit-options {
  padding: 11px 12px;
  color: var(--secondary-text);
  font-size: 20px;
  position: relative;
}

.alternate {
  background: var(--alternate-background);
}

.selected {
  background: var(--alternative-background);
}

.account-details-check {
  position: absolute;
  top: 16px;
  right: 18px;
}

.rotate {
  transform: rotate(180deg);
}

.input-error {
  border: 1.6px solid var(--primary-red);
}

.active {
  box-shadow: 0px 0px 16px 1px var(--primary-box-shadow);
  border: 1.6px solid var(--secondary-border);
}

.input-edit-wrapper{
  flex: 1;
  display: flex;
  align-items: center;
}

.input-edit-wrapper > input:first-child {
  margin-right: 22px;
}

table {
  text-align: left;
}

table th {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 16px 0 0;
}

table td {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding-top: 8px;
}

.digits-message {
  color: var(--secondary-text);
  margin-top: 8px;
}