.customer-validation-title {
	background: var(--secondary-background);
	padding: 24px;
	margin-bottom: 24px;
}

.customer-validation-subtitle {
	font-size: 20px;
	line-height: 28px;
}

.customer-validation-subtitle-gap {
	margin-top: 8px;
}

.current-situation {
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 8px;
	width: 800px;
	background: var(--section-container-primary);
	border-radius: 4px;
	margin-top: 24px;
	margin-bottom: 18px;
}

.current-situation-label {
	height: 20px;
	font-family: 'WH Hoxton';
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #344054;
	margin-bottom: 6px;
}

.customer-id {
	margin-bottom: 12px;
}

ol {
	height: 30px;
	font-family: 'WH Hoxton';
	font-weight: 400;
	font-size: 18px;
	line-height: 40px;
	color: #667085;
	padding-left: 24px;
	margin-top: 8px;
}

.check-boxes-label {
	height: 30px;
	font-family: 'WH Hoxton';
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #344054;
	margin-bottom:  19px;
	margin-top: 24px;
}

.customer-validation-check-boxes {
	display: flex;
	gap: 120px;
	align-items: center;
}

.checked {
	background-color: var(--check-box-blue);
}

.error {
	border: 1.6px solid var(--primary-red);
}

#customer-validation-subtitle {
	width: auto;
}

#pre-verification-questions-divider {
	background: var(--tooltip-primary);
	margin: 16px 0;
}

.current-situation-check-boxes {
	display: flex;
	align-items: center;
	margin-top: 8px;
}

.current-situation-yes {
	margin-right: 16px;
}

.customer-message-bold {
	margin-top: 6px;
}

.customer-validation-opaque {
	opacity: 0.4;
}

#customer-message-online-question {
	margin-top: 8px;
}

#customer-message-address-question {
	margin-top: 12px;
}

.input-error {
	border: 1.6px solid var(--primary-red);
}
