.RegistrationForm {
  margin: 124px 20px 20px 20px;
}

.tooltip-button-wrapper{
  display: flex;
  align-items: center;
}

.tooltip-button-wrapper > div:first-child{
  margin-right: 10px;
}

.standard-tooltip{
  width: 400px;
  margin: 0;
  text-align: left;
}