button.info-button-tooltip {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 24px;
  height: 24px;
  margin-top: 24px;
  position: relative;
}

.customer-message-title-button button.info-button-tooltip {
  margin-top: -2px;
  margin-left: 8px;
}

/*left align speech bubble arrow*/
.info-button-tooltip.left-align .tooltip-content::after,
.info-button-tooltip.left-align .tooltip-content {
  left: 30%;
  transform: translateX(-30%);
}

.tooltip-content {
  /*layout*/
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  /*looks*/
  cursor: initial;
  background: var(--secondary-background);
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  box-shadow: 0 0 6px 1px var(--tooltip-box-shadow);
  width: fit-content;
  border: 1px solid var(--primary-border);

  /*standard text styling*/
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--tooltip-primary);
}

div.tooltip-content::after {
  /*layout */
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: .75rem solid transparent;

  /*looks */
  border-top-color: var(--secondary-background);
  filter: drop-shadow(0 1px 1px var(--primary-border));
}

.tooltip-content-below {
  position: absolute;
  bottom: 40px;
  left: 50%;
  cursor: initial;
  background: var(--secondary-background);
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  box-shadow: 0 0 6px 1px var(--tooltip-box-shadow);
  width: fit-content;
  border: 1px solid var(--primary-border);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--tooltip-primary);
}

.tooltip-content-below::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -24px;
  left: 46%;
  transform: translateX(-50%);
  border: .75rem solid transparent;
  border-bottom-color: var(--secondary-background);
  filter: drop-shadow(0px -1px 1px var(--primary-border));
}

#below-tooltip {
  transform: translateX(-200px) translateY(135px);
}

#submit-response-tooltip-button img {
  position: absolute;
  top: -5px;
  left: 0;
}

button.info-button-tooltip img {
  height: 100%;
  width: 100%;
}

.tooltip-list {
  margin-top: 8px;
  margin-left: 8px;
}
