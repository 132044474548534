.PedToggle {
  position: absolute;
  left: 616px;
  top: 24px;
}

.ped-toggle-off {
  color: var(--secondary-background);
  font-size: 16px;
  margin-top: 18px;
  margin-left: 18px;
  font-weight: 700;
}

.ped-toggle-on {
  color: var(--secondary-background);
  font-size: 16px;
  padding: 17px 12px;
  font-weight: 700;
}

.ped-toggle-label {
  margin-right: 15px;
}

.ped-toggle-label.muted{
  color: var(--ped-off);
}
