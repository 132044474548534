.PersonalDetails {
  background: var(--secondary-background);
  padding: 24px;
}

.personal-details-options-row {
  display: flex;
  gap: 8px;
}

.personal-details-secondary-text {
  font-weight: 400;
  font-size: 16px;
  color: var(--secondary-text);
  margin-top: 8px;
}

.personal-details-input-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 200px;
}

.personal-details-arrow-down {
  position: absolute;
  top: 30px;
  right: 12px;
}

.input-error {
  border: 1.6px solid var(--primary-red);
}

.personal-details-country-code {
  color: var(--secondary-text);
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 8px;
}