.UsernamePrompt {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: var(--autofill-prompt-background);
  border-radius: 4px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--autofill-prompt-text);
}

.username-prompt-button-container {
  display: flex;
  gap: 8px;
}

.username-prompt-buttons {
  border-radius: 4px;
  width: 194px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--secondary-background);
  border: 1px solid var(--primary-border);
  color: var(--primary-text);
  flex-grow: 1;
}
