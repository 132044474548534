.marketing-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.marketing-customer-check-box {
  background: var(--pin-container);
  padding: 24px;
  font-size: 20px;
  color: var(--primary-grey);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.marketing-check-boxes {
  display: flex;
  gap: 16px;
  align-items: center;
}

.promo-offer {
  display: flex;
  font-size: 20px;
}

#promo-offer-tooltip-button {
  margin-top: -1px;
  margin-left: 10px;
}

#social-media-marketing-tooltip-button {
  margin-top: 2px;
  margin-left: 10px;
}

.check-box-edit-link-container {
  display: flex;
  align-items: center;
}

.check-box-edit-link-container .edit-selection-link {
  margin-left: 24px;
}