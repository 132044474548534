.registration-form-section {
  background: var(--secondary-background);
  margin-top: 20px;
  padding: 24px;
  position: relative;
}

.registration-form-body {
  width: 800px;
  display: flex;
  flex-direction: column;
}

.registration-form-container {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.registration-form-container.column{
  flex-direction: column;
}

.registration-form-container.margin-top{
  margin-top: 24px;
}

.registration-form-container.primary{
  background-color: var(--section-container-primary);
  padding: 24px;
  border-radius: 4px;
  /*800px width - 2 * 24px padding for background colour*/
  width: calc(800px - 48px);
}

.registration-form-buttons {
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  background: var(--secondary-background);
  font-family: 'WH Hoxton Regular';
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  padding: 11px;
}

.registration-form-buttons.secondary{
  color: var(--secondary-text);
}

.registration-form-buttons-active.secondary{
  color: var(--primary-blue);
}

.registration-form-buttons.bold{
  font-weight: 700;
}

.registration-form-buttons .ped-number{
  font-weight: 400;
}

.registration-form-buttons-active {
  background: var(--alternative-background);
  color: var(--primary-blue);
}

.ped-active {
  border: 1px solid var(--ped-active);
  border-radius: 8px;
}
