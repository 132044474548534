.pin-container {
  background: var(--pin-container);
  margin-top: 24px;
  padding: 24px;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.pin-digit-container {
  display: flex;
  margin-top: 10px;
  color: var(--secondary-text);
  font-weight: 400;
  line-height: 30px;
  align-items: center;
  border: none;
}

.pin-digit {
  width: 52px;
  height: 52px;
  background: var(--secondary-background);
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.pin-digit-container.input-error-pin .pin-digit{
  border-color: var(--primary-red);
}

.pin-digit-container.input-success .pin-digit{
  border-color: var(--primary-green);
}

.pin-digit.margin-right {
  margin-right: 22px;
}

#plus-card-tooltip-button {
  margin-top: 2px;
  margin-left: 10px;
}

#pin-tooltip-button {
  margin-top: 0;
  margin-left: 10px;
}

#submit-response-tooltip-button {
  margin-top: 0;
  margin-right: 50px;
}

.pin-title {
  display: flex;
}

p#pin-valid{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-green);
  margin: 8px 0 0 0;
}