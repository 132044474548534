.Saved {
  width: 100%;
  height: 936px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: var(--primary-grey);
  line-height: 30px;
}

.saved-top {
  background: var(--pin-container);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.saved-main {
  background-color: var(--secondary-background);
  padding: 24px;
  display: flex;
  flex-direction: column;
  color: var(--primary-text);
}

.saved-name {
  font-size: 24px;
  color: var(--primary-text);
  margin-bottom: 10px;
}

.saved-email {
  font-size: 20px;
}

.saved-message {
  font-size: 22px;
  color: var(--primary-grey);
  background-color: var(--pin-container);
  padding: 0 16px;
  width: 779px;
  margin-top: 24px;
}
