.CheckBox {
  width: 36px;
  height: 36px;
  border: 1.6px solid var(--primary-grey);
  border-radius: 4px;
  margin-right: 16px;
  background-color: var(--secondary-background);
}

.checked {
  background-color: var(--check-box-blue);
}

.check-box-container {
  display: flex;
  font-size: 20px;
  color: var(--primary-grey);
  align-items: center;
}

.error {
  border: 1.6px solid var(--primary-red);
}

.small-margin-right {
  margin-right: 8px;
}
