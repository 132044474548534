.SectionHeader {
  display: flex;
  flex-direction: column;
}

.section-header-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  display: flex;
}

.section-header-subtitle {
  font-weight: 400;
  font-size: 24px;
  color: var(--secondary-text);
  line-height: 32px;
  width: 800px;
  margin-top: 10px;
}

.section-header-subtitle-failed {
  font-weight: 400;
  font-size: 24px;
  color: var(--secondary-text);
  line-height: 32px;
  margin-top: 10px;
}
