.PhonePrompt {
  display: flex;
  padding: 24px;
  background: var(--phone-prompt);
  border-radius: 4px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-text);
  margin-top: 24px;
}

.phone-prompt-button-container {
  display: flex;
}

.phone-prompt-buttons {
  border-radius: 4px;
  width: 194px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.phone-prompt-no {
  background: var(--secondary-background);
  border: 1px solid var(--primary-border);
}

.phone-prompt-yes {
  background: var(--primary-blue);
  color: var(--secondary-background);
  border: 1px solid var(--primary-blue);
  margin-left: 8px;
}

.phone-prompt-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.phone-prompt-info {
  align-self: flex-start;
  padding-right: 24px;
}
