.InputErrorMessage {
  color: var(--primary-red);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
}

.green {
  color: var(--primary-green);
}
