.error-message {
  background-color: var(--red-background);
  margin-bottom: 20px;
  font-size: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.padding {
  padding: 24px;
}

.error-message-cross {
  height: 16px;
  width: 16px;
}

.error-message-critical {
  height: 30px;
  width: 30px;
}
