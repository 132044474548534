.App {
  background-color: var(--primary-background);
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
}

.Loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
