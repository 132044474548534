.Success {
  width: 100%;
  height: 936px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: var(--primary-grey);
  line-height: 28px;
}

.success-header {
  background-color: var(--tertiary-background);
  margin-bottom: 14px;
  font-size: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 18px 24px;
}

.success-header-text {
  margin-left: 10px;
}

.success-main {
  background-color: var(--secondary-background);
  flex-grow: 1;
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
}

.success-content {
  display: flex;
  margin-top: 24px;
}

.success-summary {
  width: 50%;
  background-color: var(--alternate-background);
  padding: 24px 24px 0 24px;
}

.success-info {
  width: 50%;
  padding: 0 40px;
}

.success-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.success-main-info {
  background: var(--pin-container);
  border-radius: 8px;
  padding: 0 24px;
  margin-bottom: 20px;
}

.success-main-info-text {
  width: 747px;
}

.margin-top {
  margin-top: 17px;
}
