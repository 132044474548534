.TopNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 20px;
  background: var(--secondary-background);
  box-shadow: 0px 0px 16px var(--secondary-box-shadow);
  position: fixed;
  top: 0px;
  width: 100%;
  font-weight: 400;
  font-size: 38px;
  justify-content: flex-end;
  z-index: 1;
}

.top-navigation-title {
  align-items: center;
  display: inline-flex;
  margin-right: auto;
}

.top-navigation-button {
  padding: 13px 20px;
  background: var(--primary-blue);
  border-radius: 4px;
  border: none;
  color: var(--secondary-background);
  font-family: 'WH Hoxton Regular';
  font-weight: 400;
  font-size: 20px;
  width: 184px;
  height: 56px;
  margin-right: 60px;
}

.submit-response-button {
  background: var(--red-background);
  width: auto;
  color: #000;
  margin-right: 12px;
}

.disabled {
  opacity: 0.4;
}

.top-navigation-version {
  color: #d3d3d3;
  font-size: 14px;
  margin: auto 15px;
}
