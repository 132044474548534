.terms-and-conditions-container {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-top: 24px;
}

.terms-and-conditions-customer-check-box {
  background: var(--pin-container);
  padding: 24px;
}
