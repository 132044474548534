:root {
  --primary-background: #f7f8fa;
  --secondary-background: #fff;
  --alternative-background: #eef2ff;
  --alternate-background: #f9fafb;
  --tertiary-background: #f7fff7;
  --primary-text: #101828;
  --secondary-text: #667085;
  --primary-blue: #2a5fc9;
  --primary-grey: #344054;
  --secondary-grey: #f2f4f7;
  --primary-border: #d0d5dd;
  --secondary-border: #98a2b3;
  --primary-box-shadow: rgba(0, 20, 60, 0.08);
  --secondary-box-shadow: rgba(16, 24, 40, 0.06);
  --tooltip-box-shadow: rgba(0, 20, 60, 0.1);
  --red-background: rgb(247, 214, 208);
  --button-background: #a0b4f4;
  --divider: #e4e7ec;
  --primary-red: #f12b2c;
  --autofill-prompt-background: #eaf1ff;
  --autofill-prompt-text: #3356bc;
  --primary-green: #27ae60;
  --phone-prompt: #dcebff;
  --pin-container: #ebf1ff;
  --tooltip-primary: #7383a6;
  --check-box-blue: #2572e4;
  --ped-active: #adc9ff;
  --ped-off: #eaeaea;
  --section-container-primary: #dcebff;
  --failed-container: #f9f9f9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'WH Hoxton Regular';
  color:  var(--primary-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'WH Hoxton Regular';
  src: url('./assets/fonts/WHHoxtonWeb-Regular.woff');
}
