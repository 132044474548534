.customer-message {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 4px;
  width: 801px;
  height: hug(112px);
  background: var(--failed-container);
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
}

.customer-message.margin-top{
  margin-top: 24px;
}

.customer-message.margin-bottom{
  margin-bottom: 24px;
}


.customer-message-title {
  font-family: 'WH Hoxton';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  width: 761px;
  color: var(--tooltip-primary);
  flex: none;
  order: 0;
  flex-grow: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.customer-message-content {
  width: 761px;
  font-family: 'WH Hoxton';
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: var(--tooltip-primary);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0;
}

.customer-message-white {
  background-color: var(--secondary-background);
}
