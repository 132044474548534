.Failed {
  width: 100%;
  height: 936px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: var(--primary-grey);
  line-height: 30px;
}

.failed-header {
  background-color: var(--tertiary-background);
  margin-bottom: 20px;
  font-size: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 24px;
}

.failed-header-text {
  margin-left: 10px;
}

.failed-main {
  background-color: var(--secondary-background);
  flex-grow: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.failed-content {
  display: flex;
  margin-top: 24px;
}

.failed-summary {
  width: 50%;
  background-color: var(--alternate-background);
  padding: 24px 24px 0 24px;
}

.failed-info {
  width: 50%;
  padding: 0 40px;
}

.failed-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.failed-main-info {
  background: var(--pin-container);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 37px;
}

.failed-main-info-text {
  width: 746px;
}

.margin-top {
  margin-top: 17px;
}

.failed-next-steps {
  padding: 24px;
  width: 800px;
  background: var(--failed-container);
  border-radius: 4px;
  margin-top: 24px;
}

.failed-highlight {
  background: var(--pin-container);
  padding: 8px;
  border-radius: 4px;
  margin-top: 12px;
}
