.Button {
  margin-top: 8px;
  font-family: 'WH Hoxton Regular';
  color: var(--secondary-background);
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  padding: 11px 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  flex-grow: 1;
  background: var(--button-background);
}

.button-margin-top {
  margin-top: 24px;
}

.button-width {
  flex-grow: 0;
  width: 260px;
}

.button-active {
  background: var(--primary-blue);
}

.button-unlink {
  background: var(--secondary-grey);
  color: var(--primary-blue);
}
