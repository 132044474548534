.postcode-lookup {
  display: flex;
  flex-direction: column;
  position: relative;
}

.postcode-options-container {
  position: absolute;
  top: 116px;
  left: 0px;
  width: 800px;
  background: var(--secondary-background);
  box-shadow: 0px 0px 16px 1px var(--primary-box-shadow);
  border-radius: 4px;
  color: var(--secondary-text);
  z-index: 1;
}

.postcode-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  gap: 12px;
}

.postcode {
  color: var(--primary-text);
}

.alternate {
  background: var(--alternate-background);
}

.selected {
  background: var(--alternative-background);
}

.postcode-check {
  position: absolute;
  right: 18px;
}

.postcode-selected {
  display: flex;
  align-items: center;
  gap: 12px;
}

.active {
  box-shadow: 0px 0px 16px 1px var(--primary-box-shadow);
  border: 1.6px solid var(--secondary-border);
}

.postcode-options {
  max-height: 312px;
  overflow-y: scroll;
}

.postcode-options-footer {
  background-color: var(--secondary-background);
  padding: 24px;
  font-size: 20px;
  color: var(--primary-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-container {
  background: var(--alternate-background);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.address-title {
  font-weight: 400;
  font-size: 20px;
  color: var(--primary-grey);
}

.margin-top {
  margin-top: 24px;
}

.address-field {
  display: flex;
  flex-direction: column;
  position: relative;
}

.address-clear {
  position: absolute;
  top: 70px;
  right: 11px;
  color: var(--primary-blue);
  font-weight: 400;
  font-size: 20px;
}

.top {
  top: 46px;
}

.enter-manually-link{
  cursor: pointer;
}